const Merch = () => {
    return (
        <>
            <div
                className="max-h-[40vh] min-h-[40vh] w-full overflow-hidden flex flex-col justify-center items-center pt-20">
                <div className={'absolute max-h-[40vh] min-h-[40vh] w-full top-0 right-0 z-[0]'} style={{
                    backgroundImage: `url(/image/top.JPEG)`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}/>
                <h1 className="z-10 text-white text-4xl font-bold">
                    Renntaxi</h1>
            </div>

            <div className="flex flex-row">
                <div className="max-lg:hidden max-w-96 min-w-96 min-h-[500px] rounded-lg relative">
                    <div className={'w-full min-h-[500px] rounded-lg'} style={{
                        backgroundImage: `url(/image/top.JPEG)`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}/>
                </div>
                <div className="mx-5">
                    <h1 className="text-3xl font-bold">Unser Merch</h1>
                    <p className="mt-5 text-lg text-justify">
						Zeigen Sie Ihre Leidenschaft für den Motorsport mit dem offiziellen Zen Racing Merchandise!

Entdecken Sie unsere exklusive Kollektion von hochwertigen Fanartikeln – von stylischen T-Shirts und Hoodies bis hin zu Kappen und Accessoires. Jedes Stück spiegelt die Dynamik und den Teamgeist von Zen Racing wider.
					</p>
                </div>
            </div>
        </>
    )
}

export default Merch;