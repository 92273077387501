import { useState } from 'react'
import { Dialog, Popover } from '@headlessui/react'
import {
    Bars3Icon,
    XMarkIcon,
} from '@heroicons/react/24/outline'
import HeaderLink from "../header/HeaderLink";
import {Link} from "react-router-dom";

export default function Header() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    return (
        <header className="bg-white absolute w-full z-10">
            <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
                <div className="flex lg:flex-1">
                    <Link to="/" className="-m-1.5 p-1.5">
                        <h1 className="text-2xl font-bold">Zen-Racing</h1>
                    </Link>
                </div>
                <div className="flex lg:hidden">
                    <button
                        type="button"
                        className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                        onClick={() => setMobileMenuOpen(true)}
                    >
                        <span className="sr-only">Open main menu</span>
                        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                <Popover.Group className="hidden lg:flex lg:gap-x-12">
                    <HeaderLink href={"/"} mobile={false}><p>Home</p></HeaderLink>
                    <HeaderLink href={"/aboutus"} mobile={false}><p>Über uns</p></HeaderLink>
                    <HeaderLink href={"/racetaxi"} mobile={false}><p>Renntaxi</p></HeaderLink>
                    <HeaderLink href={"/sponsors"} mobile={false}><p>Sponsoren</p></HeaderLink>
                    <HeaderLink href={"/lineup"} mobile={false}><p>Line-UP</p></HeaderLink>
                    <HeaderLink href={"/merch"} mobile={false}><p>Merch</p></HeaderLink>
                </Popover.Group>
                <div className="hidden lg:flex lg:flex-1 lg:gap-x-8 lg:justify-end">
                </div>
            </nav>
            <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                <div className="fixed inset-0 z-10 bg-white" />
                <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-secondary px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                    <div className="flex items-center justify-between">
                        <Link to="/" className="-m-1.5 p-1.5">
                            <h1 className="text-2xl font-bold">Zen-Racing</h1>
                        </Link>
                        <button
                            type="button"
                            className="-m-2.5 rounded-md p-2.5 text-gray-700"
                            onClick={() => setMobileMenuOpen(false)}
                        >
                            <span className="sr-only">Close menu</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                    <div className="mt-6 flow-root">
                        <div className="-my-6 divide-y divide-gray-500/10">
                            <div className="space-y-2 py-6">
                                <HeaderLink href={"/"} mobile={true}><p>Home</p></HeaderLink>
                                <HeaderLink href={"/aboutus"} mobile={true}><p>Über uns</p></HeaderLink>
                                <HeaderLink href={"/racetaxi"} mobile={true}><p>Renntaxi</p></HeaderLink>
                                <HeaderLink href={"/sponsors"} mobile={true}><p>Sponsoren</p></HeaderLink>
                                <HeaderLink href={"/lineup"} mobile={true}><p>Line-UP</p></HeaderLink>
                                <HeaderLink href={"/merch"} mobile={true}><p>Merch</p></HeaderLink>
                            </div>
                            <div className="py-6">
                            </div>
                        </div>
                    </div>
                </Dialog.Panel>
            </Dialog>
        </header>
    )
}