import {useEffect, useState} from "react";

const LineUpPage = () => {
    const [termine, setTermine] = useState<string[]>();
    useEffect(() => {
        fetch('/config/lineup.json').then(value => value.json()).then(value => setTermine(value))
    }, []);

    return (
        <>
            <div
                className="max-h-[40vh] min-h-[40vh] w-full overflow-hidden flex flex-col justify-center items-center pt-20">
                <div className={'absolute max-h-[40vh] min-h-[40vh] w-full top-0 right-0 z-[0]'} style={{
                    backgroundImage: `url(/image/top.JPEG)`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}/>
                <h1 className="z-10 text-white text-4xl font-bold">Line-UP</h1>
            </div>
            <div className="grid grid-cols-2">
                {termine?.map(value => {
                    return (
                        <>
                            <h1 className="py-5 bg-gray-100 text-center m-2 rounded-lg text-xl font-bold">{value}</h1>
                        </>
                    )
                })}
            </div>
        </>
    )
}

export default LineUpPage;