import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import BasicLayout from "./component/layout/BasicLayout";
import LineUpPage from "./page/LineUpPage";
import HomePage from "./page/HomePage";
import ImprintPage from "./page/ImprintPage";
import SponsorenPage from "./page/SponsorenPage";
import RennTaxiPage from "./page/RennTaxiPage";
import AboutUsPage from "./page/AboutUsPage";
import MerchPage from "./page/Merch";

const router = createBrowserRouter([
  {
    path: "*",
    element: <BasicLayout/>,
      children: [
          {
              path: "",
              element: <HomePage/>
          },
          {
              path: "lineup",
              element: <LineUpPage/>
          },
          {
              path: "imprint",
              element: <ImprintPage/>,
          },
          {
              path: "aboutus",
              element: <AboutUsPage/>,
          },
          {
              path: "sponsors",
              element: <SponsorenPage/>,
          },
          {
              path: "racetaxi",
              element: <RennTaxiPage/>
          },
          {
              path: "merch",
              element: <MerchPage/>
          }
      ]
  },
]);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
