const RennTaxiPage = () => {
    return (
        <>
            <div
                className="max-h-[40vh] min-h-[40vh] w-full overflow-hidden flex flex-col justify-center items-center pt-20">
                <div className={'absolute max-h-[40vh] min-h-[40vh] w-full top-0 right-0 z-[0]'} style={{
                    backgroundImage: `url(/image/top.JPEG)`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}/>
                <h1 className="z-10 text-white text-4xl font-bold">
                    Renntaxi</h1>
            </div>

            <div className="flex flex-row">
                <div className="max-lg:hidden max-w-96 min-w-96 min-h-[500px] rounded-lg relative">
                    <div className={'w-full min-h-[500px] rounded-lg'} style={{
                        backgroundImage: `url(/image/top.JPEG)`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}/>
                </div>
                <div className="mx-5">
                    <h1 className="text-3xl font-bold">Renntaxi</h1>
                    <p className="mt-5 text-lg text-justify">
						<h2><b>Renn-Taxi Fahrten</b></h2>

Erleben Sie den Adrenalinkick Ihres Lebens mit Zen Racing Renn-Taxi Fahrten!

Träumen Sie davon, die legendäre Nürburgring-Nordschleife hautnah zu erleben? Bei unseren Renn-Taxi Fahrten haben Sie die einmalige Gelegenheit, als Beifahrer in einem echten Rennwagen mitzufahren und die faszinierende Welt des Motorsports hautnah zu erleben.

<h2><b>Das Erlebnis</b></h2>

Setzen Sie sich neben einen unserer erfahrenen Rennfahrer und spüren Sie die unbändige Kraft und Präzision unseres Rennwagens, während wir durch die anspruchsvollsten Kurven und Geraden der "Grünen Hölle" rasen. Erleben Sie Beschleunigung, Kurvengeschwindigkeit und Bremskraft, die sonst nur den Fahrern vorbehalten sind.

<h2><b>Warum Zen Racing Renn-Taxi?</b></h2>

Adrenalin pur: Erleben Sie die legendäre Nürburgring-Nordschleife mit all ihren Herausforderungen und Emotionen.
Professionelle Fahrer: Unsere Rennfahrer sind erfahrene Profis, die jeden Zentimeter der Strecke kennen und Ihnen ein sicheres und aufregendes Erlebnis bieten.
High-Performance Fahrzeuge: Unsere Rennwagen sind auf dem neuesten Stand der Technik und bieten ein unvergessliches Fahrerlebnis.
Sicherheit an erster Stelle: Ihre Sicherheit hat für uns oberste Priorität. Sie erhalten eine umfassende Einweisung und die notwendige Sicherheitsausrüstung für Ihre Fahrt.
	
<h2><b>Buchung</b></h2>

Die Renn-Taxi Fahrten sind eine perfekte Geschenkidee für Motorsport-Enthusiasten oder ein Highlight für Ihren eigenen Besuch am Nürburgring. Unsere verschiedenen Pakete bieten für jeden das passende Erlebnis.
					</p>
                </div>
            </div>
        </>
    )
}

export default RennTaxiPage;