const AboutUsPage = () => {
    return (
        <>
            <div
                className="max-h-[40vh] min-h-[40vh] w-full overflow-hidden flex flex-col justify-center items-center pt-20">
                <div className={'absolute max-h-[40vh] min-h-[40vh] w-full top-0 right-0 z-[0]'} style={{
                    backgroundImage: `url(/image/top.JPEG)`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}/>
                <h1 className="z-10 text-white text-4xl font-bold">Über uns</h1>
            </div>

            <div className="flex flex-row pt-20">
                <div className="mx-5">
                    <h1 className="text-3xl font-bold">DEIN RENNTEAM AM NÜRBURGRING</h1>
                    <p className="mt-5 text-lg text-justify">
						<h2><b>Willkommen bei Zen Racing!</b></h2> 
Zen Racing ist mehr als nur ein Rennteam – es ist eine Leidenschaft, ein Lifestyle und ein Streben nach Perfektion auf der Rennstrecke. Gegründet von einer Gruppe leidenschaftlicher Motorsport-Enthusiasten, steht Zen Racing für Präzision, Teamgeist und die unbändige Freude am Rennsport.

Unser Team nimmt mit Stolz an der Rundstrecken-Challenge Nürburgring (RCN) und der Gleichmäßigkeitsprüfung (GLP) teil, wo wir unser Können und unsere Hingabe unter Beweis stellen. Der legendäre Nürburgring, bekannt als die "Grüne Hölle", bietet die perfekte Kulisse für unseren unermüdlichen Einsatz und unser Streben nach Erfolg.

						<h2><b>Unsere Mission</b></h2> 

Bei Zen Racing streben wir danach, die perfekte Balance zwischen Geschwindigkeit, Technik und Teamarbeit zu finden. Unser Name "Zen" symbolisiert diese Harmonie und Ruhe, die wir auf der Rennstrecke anstreben – eine perfekte Abstimmung von Mensch und Maschine.

						<h2><b>Unser Team</b></h2> 

Unser Team besteht aus erfahrenen Fahrern, talentierten Technikern und engagierten Support-Mitarbeitern, die alle eine gemeinsame Leidenschaft teilen: den Motorsport. Jeder einzelne von uns bringt einzigartige Fähigkeiten und Erfahrungen mit, die zusammen das Herz und die Seele von Zen Racing bilden.

						<h2><b>Unsere Werte</b></h2> 

Leidenschaft: Der Motor, der uns antreibt, ist unsere unermessliche Liebe zum Motorsport.
Präzision: Wir streben nach technischer Perfektion und exzellenter Performance.
Teamgeist: Gemeinsam sind wir stärker – unser Erfolg basiert auf Zusammenarbeit und gegenseitigem Vertrauen.
Kontinuität: Wir lernen ständig dazu und verbessern uns stetig, um immer an der Spitze zu bleiben.

						<h2><b>Kontakt</b></h2> 

Möchten Sie mehr über Zen Racing erfahren oder uns auf unserer Reise unterstützen? Kontaktieren Sie uns gerne – wir freuen uns darauf, von Ihnen zu hören!
E-Mail: finn.schmid@zen-racing.de
Tel: +49 151 21252179
					
					
					
					</p>
                </div>
                <div className="max-lg:hidden max-w-96 min-w-96 min-h-[500px] rounded-lg relative">
                    <div className={'w-full min-h-[500px] rounded-lg'} style={{
                        backgroundImage: `url(/image/top.JPEG)`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}/>
                </div>
            </div>
        </>
    )
}

export default AboutUsPage;