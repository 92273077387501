import React, { useEffect, useRef } from 'react';

interface InstagramPostProps {
    url: string;
}

const InstagramPost: React.FC<InstagramPostProps> = ({ url }) => {
    const embedContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // Laden des Instagram Embed-API-SDK-Skripts
        const script = document.createElement('script');
        script.src = 'https://www.instagram.com/embed.js';
        script.async = true;

        if (embedContainerRef.current) {
            embedContainerRef.current.appendChild(script);
        }

        return () => {
            // Aufräumen beim unmount der Komponente
            if (embedContainerRef.current) {
                embedContainerRef.current.removeChild(script);
            }
        };
    }, []);

    return (
        <div ref={embedContainerRef} className="instagram-post mx-2">
            <blockquote
                className="instagram-media"
                data-instgrm-permalink={url}
                data-instgrm-version="13"
                style={{ background: '#FFF', border: '0', borderRadius: '3px', boxShadow: '0 0 1px 0 rgba(0,0,0,0.5)', margin: '1px', maxWidth: '540px', minWidth: '326px', padding: '0', width: 'calc(100% - 2px)' }}>
            </blockquote>
        </div>
    );
};

export default InstagramPost;
