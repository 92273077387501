import React from 'react';
import Header from "./Header";
import Footer from "./Footer";
import {Outlet} from "react-router-dom";

export const BasicLayout: React.FC = () => {
    return (
        <>
            <main className="w-full h-screen bg-primary-light">
                <Header/>
                <div className="max-w-7xl min-h-[95vh] mx-auto p-6 lg:px-8">
                    <Outlet />
                </div>
                <Footer/>

            </main>
        </>
    )
}

export default BasicLayout;