import React from "react";

interface Props {
    href: string;
    children: React.JSX.Element;
    mobile: boolean;
}

const HeaderLink: React.FC<Props> = ({href, mobile, children}) => {
    return (
        <>
            <a href={href}
                  className={mobile ? " -mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 transition-all duration-300" : "text-sm font-semibold leading-6 text-gray-900"}>
                {children}
            </a>
        </>
    )
}

export default HeaderLink;