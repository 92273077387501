import {useEffect, useState} from "react";

interface Sponsoren {
    logo: string;
    website: string;
    name: string;
}

const SponsorenPage = () => {
    const [sponsoren, setSponsoren] = useState<Sponsoren[]>();
    useEffect(() => {
        fetch('/config/sponsors.json').then(value => value.json()).then(value => setSponsoren(value))
    }, []);

    return (
        <>
            <div
                className="max-h-[40vh] min-h-[40vh] w-full overflow-hidden flex flex-col justify-center items-center pt-20">
                <div className={'absolute max-h-[40vh] min-h-[40vh] w-full top-0 right-0 z-[0]'} style={{
                    backgroundImage: `url(/image/top.JPEG)`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}/>
                <h1 className="z-10 text-white text-4xl font-bold">Sponsoren</h1>
            </div>

            <div className="grid grid-cols-3 max-[800px]:grid-cols-2 max-[544px]:grid-cols-1 flex-wrap justify-center">
                {sponsoren?.map(value => {
                    return (
                        <>
                            <a className="h-40 mx-auto my-2 w-60 bg-gray-50 rounded-lg shadow justify-center items-center flex flex-col relative"
                               target={"_blank"} href={value.website} rel="noreferrer">
                                <img src={value.logo} className="mx-auto px-5 max-h-24 max-w-48 block relative"/>
                                <h1 className="pt-5 text-lg font-bold">{value.name}</h1>
                            </a>
                        </>
                    )
                })}
            </div>

            <div className="text-center pt-20">
                <h1 className="text-2xl font-bold">Warum Sponsor</h1>
                <p className="max-w-2xl mx-auto pt-5 text-lg">
				<h2><b>Werden Sie Sponsor von Zen Racing!</b></h2>

Machen Sie Ihre Marke sichtbar auf der Rennstrecke und darüber hinaus – als Sponsor von Zen Racing!

<h2><b>Warum Sponsoring?</b></h2>

Sponsoring bei Zen Racing bietet eine einzigartige Gelegenheit, Ihre Marke mit Geschwindigkeit, Präzision und Leidenschaft zu verbinden. Motorsport begeistert Millionen von Fans weltweit und bietet eine unvergleichliche Plattform, um Ihre Marke einem breiten und engagierten Publikum zu präsentieren.

<h2><b>Ihre Vorteile als Sponsor</b></h2>

Hohe Sichtbarkeit: Ihr Logo auf unseren Rennwagen, der Teambekleidung und der gesamten Ausrüstung sorgt für maximale Markenpräsenz bei jedem Rennen.
Exklusives Engagement: Nutzen Sie unsere Rennveranstaltungen, um Kunden, Partner und Mitarbeiter zu exklusiven Events einzuladen und ihnen ein unvergessliches Erlebnis zu bieten.
Positive Assoziationen: Verbinden Sie Ihre Marke mit den Werten des Motorsports – Geschwindigkeit, Innovation, Teamarbeit und Siegeswille.
Medienpräsenz: Profitieren Sie von umfangreicher Berichterstattung und Social-Media-Aktivitäten rund um unsere Rennen und Events.
Netzwerk: Nutzen Sie unser umfangreiches Netzwerk im Motorsport, um neue Geschäftskontakte zu knüpfen und Synergien zu schaffen.

<h2><b>Wie können Sie uns unterstützen</b>?</h2>

Wir bieten verschiedene Sponsoring-Pakete, die auf Ihre Bedürfnisse und Ziele zugeschnitten sind. Ob als Hauptsponsor, Co-Sponsor oder mit speziellen Partnerschaften – wir finden die perfekte Lösung für Ihre Marke.
				
				</p>
            </div>
        </>
    )
}

export default SponsorenPage;