const ImprintPage = () => {
    return (
        <>
            <div className="pb-20">
                <h3 className="text-2xl pb-5 font-bold uppercase">Impressum</h3>
                <h4 className="text-xl pb-3 font-semibold">Impressum // Diensteanbieter</h4>
                <p className="text-lg pl-1">
                    Finn Schmid<br />
                    Mittelstraße 11<br />
                    56305 Döttesfeld<br />
                    Deutschland
                </p>
                <h4 className="text-xl pb-3 pt-5 font-semibold">Kontaktmöglichkeiten</h4>
                <p className="text-lg pl-1">
                    E-Mail-Adresse: <a href="mailto:finnschmid02@gmail.com">finnschmid02@gmail.com</a><br />
                    Telefon: <a href="tel:+4916092595136">++4915121252179</a>
                </p>
                <h4 className="text-xl pb-3 pt-5 font-semibold">Vertretungsberechtigte Personen</h4>
                <p className="text-lg pl-1">Vertretungsberechtigt: Finn Schmid, Anschrift wie oben stehend.</p>
                <h4 className="text-xl pb-3 pt-5 font-semibold">Online-Streitbeilegung (OS)</h4>
                <p className="text-lg pl-1">
                    Online-Streitbeilegung: Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit, die Sie unter <a href="https://ec.europa.eu/consumers/odr/" target="_blank">https://ec.europa.eu/consumers/odr/</a> finden. Verbraucher haben die Möglichkeit, diese Plattform für die Beilegung ihrer Streitigkeiten zu nutzen.
                </p>
                <h4 className="text-xl pb-3 pt-5 font-semibold">Disclaimer – rechtliche Hinweise</h4>
                <p className="text-lg pl-1">
                    § 1. <b>Haftungsausschluss:</b> Die Inhalte dieses Onlineangebotes wurden sorgfältig und nach unserem aktuellen Kenntnisstand erstellt, dienen jedoch nur der Information und entfalten keine rechtlich bindende Wirkung, sofern es sich nicht um gesetzlich verpflichtende Informationen (z.B. das Impressum, die Datenschutzerklärung, AGB oder verpflichtende Belehrungen von Verbrauchern) handelt. Wir behalten uns vor, die Inhalte vollständig oder teilweise zu ändern oder zu löschen, soweit vertragliche Verpflichtungen unberührt bleiben. Alle Angebote sind freibleibend und unverbindlich.
                </p>
                <p className="text-lg pl-1">
                    § 2. <b>Links auf fremde Webseiten:</b> Die Inhalte fremder Webseiten, auf die wir direkt oder indirekt verweisen, liegen außerhalb unseres Verantwortungsbereiches und wir machen sie uns nicht zu Eigen. Für alle Inhalte und Nachteile, die aus der Nutzung der in den verlinkten Webseiten aufrufbaren Informationen entstehen, übernehmen wir keine Verantwortung.
                </p>
                <p className="text-lg pl-1">
                    § 3. <b>Urheberrechte und Markenrechte:</b> Alle auf dieser Website dargestellten Inhalte, wie Texte, Fotografien, Grafiken, Marken und Warenzeichen sind durch die jeweiligen Schutzrechte (Urheberrechte, Markenrechte) geschützt. Die Verwendung, Vervielfältigung usw. unterliegen unseren Rechten oder den Rechten der jeweiligen Urheber bzw. Rechteinhaber.
                </p>
            </div>
        </>
    );
}

export default ImprintPage;
