import InstagramPost from "../component/InstagramPost";
import {useEffect, useState} from "react";

const HomePage = () => {
    const [posts, setPosts] = useState<string[]>();
    useEffect(() => {
        fetch('/config/posts.json').then(value => value.json()).then(value => setPosts(value))
    }, []);

    return (
        <>
            <div
                className="max-h-[100vh] min-h-[100vh] w-full overflow-hidden flex flex-col pt-20 max-[875px]:items-center">
                <div className={'absolute max-h-[100vh] min-h-[100vh] w-full top-0 right-0 z-[0]'} style={{
                    backgroundImage: `url(/image/top.JPEG)`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    filter: 'grayscale(50%)',
                }}/>
                <div
                    className="min-[875px]:right-1/4 max-[875px]:block max-[875px]:z-10 max-[875px]:px-5 min-[875px]:top-1/3 max-[875px]:pt-20 min-[875px]:absolute">
                    <h1 className="z-10 text-white text-4xl font-bold w-full">Zen-Racing</h1>
                    <p className="pt-5 text-white text-xl font-bold">DEIN RENNTEAM AM NÜRBURGRING</p>
                    <p className="text-white text-lg font-semibold max-w-96">Willst Du mehr über unser Team und unsere
                        Leistung erfahren?</p>
                </div>
            </div>
            <div className="">
                <div className="flex flex-row flex-wrap justify-center">
                    {posts?.map(value => {
                        return <InstagramPost url={value}/>
                    })}
                </div>
            </div>
        </>
    )
}

export default HomePage;